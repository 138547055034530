<template>
  <div class="login">
    <b-form>
      <b-form-group
        id="UserIdGroup"
        label="User ID:"
        label-for="UserId">
        <b-form-input
          v-model="userId"
          id="userId"
          ref="userId"
          type="text"
          required
          placeholder="Enter User ID">
        </b-form-input>
      </b-form-group>
      <b-form-group
        id="PasswordGroup"
        label="Password:"
        label-for="Password">
        <b-form-input
          id="password"
          type="password"
          required
          placeholder="Enter Password">
        </b-form-input>
      </b-form-group>
      <b-button
        type="submit"
        variant="primary"
        @shortkey="logIn"
        @mousedown="logIn"
        class="b-button">Submit
      </b-button>
    </b-form>
  </div>
</template>

<script>

export default {
  name: 'login',
  methods: {
    logIn: function () {
      // Reinitialize all permissions
      this.$store.state.navigation.forEach(function (item) {
        item.visible = true
        item.enabled = true
        item.actions.forEach(function (item) {
          item.visible = true
          item.enabled = true
        })
      })
      this.$store.state.selectedNavigation = ''
      this.$store.state.selectedAction = ''
      this.$store.state.isLogged = true
      this.$store.state.userName = this.userId.toLowerCase().trim()
      this.$store.state.showHomePage = true
      // Set permissions
      if (this.userId === 'admin') {
        this.$store.state.navigation[1].visible = false
        this.$store.state.navigation[2].visible = false
        this.$store.state.navigation[3].visible = false
        this.$store.state.navigation[4].visible = false
        this.$store.state.navigation[5].visible = false
        this.$store.state.showHomePage = false
        this.$router.push('/Secure/AdminHome')
      } else if (this.userId === 'pi') {
        this.$store.state.navigation[0].visible = false
        this.$router.push('/Secure/Home')
      } else if (this.userId === 'reviewer1') {
        this.$store.state.navigation[0].visible = false
        this.$store.state.navigation[1].actions[0].visible = false
        this.$store.state.navigation[1].actions[1].visible = false
        this.$store.state.navigation[1].actions[2].visible = false
        this.$store.state.navigation[1].actions[3].visible = false
        this.$store.state.navigation[1].actions[4].visible = false
        this.$store.state.navigation[1].actions[5].visible = false
        this.$router.push('/Secure/Home')
      } else if (this.userId === 'reviewer2') {
        this.$store.state.navigation[0].visible = false
        this.$store.state.navigation[1].actions[0].visible = false
        this.$store.state.navigation[1].actions[1].visible = false
        this.$store.state.navigation[1].actions[2].visible = false
        this.$store.state.navigation[1].actions[3].visible = false
        this.$store.state.navigation[1].actions[4].visible = false
        this.$store.state.navigation[1].actions[5].visible = false
        this.$store.state.navigation[1].actions[6].enabled = false
        this.$store.state.navigation[1].actions[7].enabled = false
        this.$store.state.navigation[4].visible = false
        this.$router.push('/Secure/Home')
      } else {
        this.$router.push('/Secure/Home')
      }
    }
  },
  data () {
    return {
      userId: ''
    }
  }
}
</script>
